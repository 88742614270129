import React, { useContext, useReducer } from "react";

export const UserContext = React.createContext();

export const reducer = (state, action) => {
  switch (action.type) {
    case "setUser":
      return { ...state, user: action.user };
    case "setAuthorized":
      return { ...state, authorized: action.authorized };
    case "loading":
      return { ...state, userLoading: action.loading };
    default:
      return state;
  }
};

export const useUser = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    authorized: true,
    user: {
      addresses: [],
      lastName: "",
      firstName: "",
      middleName: "",
      phone: "",
      email: "",
      type: 0,
      markup: 0,
      delayDayCount: 0,
      creditSumMax: 0,
      orgNameShort: "",
      balance: 0,
    },
    userLoadnig: false,
  });

  const setUser = (user) => dispatch({ type: "setUser", user });

  const setAuthorized = (authorized) =>
    dispatch({ type: "setAuthorized", authorized });

  const setUserLoading = (loading) => dispatch({ type: "loading", loading });

  return (
    <UserContext.Provider
      value={{
        authorized: state.authorized,
        user: state.user,
        loading: state.loading,
        setUserLoading,
        setUser,
        setAuthorized,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
