import { axiosInstance } from "./api";

export type TGetBalance = {
  paginationCount: number;
  paginationOffset: number;
  search?: string;
  dateStart?: string;
  dateEnd?: string;
  number?: string;
  status?: string | null;
};

export const getBalance = async (props: TGetBalance) => {
  const params = {
    ...props,
    "pagination[count]": props.paginationCount,
    "pagination[offset]": props.paginationOffset,
  };
  const res = await axiosInstance.get("/balance.getDocuments", { params });
  return res.data;
};

export type TRequestReconciliationAct = {
  email: string;
  dateStart: string;
  dateEnd: string;
};

export const requestReconciliationAct = async (
  props: TRequestReconciliationAct
) => {
  const res = await axiosInstance.post("/orders.reconciliationAct", props);
  return res.data;
};
