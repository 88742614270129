import React, { useContext, useReducer } from "react";

export const CartContext = React.createContext();

export const reducer = (state, action) => {
  switch (action.type) {
    //  case "add":
    //   return { ...state, cart: [...state.cart, action.prod] };
    case "set":
      return { ...state, cart: action.cart };
    case "loading":
      return { ...state, cartLoading: action.loading };
    case "added":
      return { ...state, added: action.added };
    case "setFavorite":
      return { ...state, favorite: action.favorite };
    default:
      return state;
  }
};

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    cart: [],
    cartLoadnig: false,
    added: null,
    favorite: [],
  });

  // const addToCart = (prod) => dispatch({ type: "add", prod });

  const setCart = (cart) => dispatch({ type: "set", cart });

  const setCartLoading = (loading) => dispatch({ type: "loading", loading });

  const setAdded = (added) => dispatch({ type: "added", added });

  const setFavorite = (favorite) => dispatch({ type: "setFavorite", favorite });

  return (
    <CartContext.Provider
      value={{
        cart: state.cart,
        cartLoading: state.cartLoading,
        added: state.added,
        favorite: state.favorite,
        setCartLoading,
        setCart,
        setAdded,
        setFavorite,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
