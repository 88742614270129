import { FC, ChangeEvent, useState, useEffect } from "react";
import classNames from "classnames";

import { quantityValid } from "utils/validation";

import styles from "./counter.module.scss";

interface ICounter {
  max: number;
  quantity: number;
  setQuantity?: (value: number) => void;
  breakpoint?: string;
}

export const Counter: FC<ICounter> = ({
  max,
  quantity,
  setQuantity,
  breakpoint = "desktop",
}) => {
  const [value, setValue] = useState(quantity);
  const [typingTimeout, setTypingTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  useEffect(() => {
    if (quantity === value) return;

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(() => {
        setQuantity && setQuantity(value);
      }, 300)
    );
  }, [value]);

  return (
    <div className={classNames(styles.counter, styles[breakpoint])}>
      <button
        onClick={() => setValue((prev: number) => quantityValid(prev - 1, max))}
      >
        -
      </button>
      <input
        type="text"
        maxLength={4}
        value={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setValue(quantityValid(+e.target.value, max))
        }
      />
      <button onClick={() => setValue((prev) => quantityValid(prev + 1, max))}>
        +
      </button>
    </div>
  );
};
