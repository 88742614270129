import { FC, useState } from "react";
import { Formik } from "formik";
import { object, setLocale, string } from "yup";
import { toast } from "react-toastify";
import classNames from "classnames";

import { TVinRequest, vinRequest } from "services/orderApi";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { useBreakpoint } from "hooks/useBreakpoint";
import { Spinner2 } from "components/Spinner2";
import { locale } from "validationLocale";

import styles from "./vinRequest.module.scss";

setLocale(locale);

const VinRequestSchema = object().shape({
  vin: string().min(17, "Длина VIN номера 17 символов").required(),
  comment: string().min(3, "Слишком короткий комментарий").required(),
});

export const VinRequest: FC = () => {
  const { breakpoint } = useBreakpoint();
  const [loading, setLoading] = useState(false);

  const submitHandler = (values: TVinRequest, resetForm: () => void) => {
    setLoading(true);
    vinRequest(values)
      .then(() => {
        toast.success("Запрос по VIN успешно отправлен");
        resetForm();
      })
      .catch((e) => toast.error(e.response?.data?.message))
      .finally(() => setLoading(false));
  };

  return (
    <div className={classNames(styles.wrapper, styles[breakpoint])}>
      {loading && <Spinner2 overlay />}
      <div className={styles.title}>Запрос по VIN</div>
      <Formik
        initialValues={{
          vin: "",
          comment: "",
        }}
        validationSchema={VinRequestSchema}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          submitHandler(values, resetForm);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Input
              id="vin"
              label="VIN номер"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.vin}
              className={styles.input}
              error={errors.vin && touched.vin ? errors.vin : null}
            />
            <Input
              id="comment"
              type="textarea"
              label="Комментарий"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.comment}
              className={styles.textarea}
              error={errors.comment && touched.comment ? errors.comment : null}
            />
            <div className={styles.buttonBlock}>
              <Button
                type="submit"
                disabled={isSubmitting}
                breakpoint={breakpoint}
              >
                Отправить запрос
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
