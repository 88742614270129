import { FC, ReactNode, useEffect, useState, ChangeEvent } from "react";
import classNames from "classnames";

import styles from "./checkbox.module.scss";

interface ICheckbox {
  id: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  children?: ReactNode;
  value?: string;
  name?: string;
  disabled?: boolean;
  error?: string | false | undefined;
  className?: string;
}

export const Checkbox: FC<ICheckbox> = ({
  children = "",
  id,
  value = undefined,
  name = "",
  checked,
  onChange,
  disabled = false,
  error = false,
  className,
}) => {
  const [active, setActive] = useState(checked);
  useEffect(() => setActive(checked), [checked]);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setActive(e.target.checked);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div
      className={classNames(styles.checkbox, className, {
        [styles.error]: error,
      })}
    >
      <input
        type="checkbox"
        id={`checkbox_${id}`}
        name={name}
        value={value}
        onChange={changeHandler}
        checked={active}
        disabled={disabled}
      />
      <label htmlFor={`checkbox_${id}`}>{children}</label>
    </div>
  );
};
