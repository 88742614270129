import { axiosInstance2, axiosInstance } from "./api";

export const suggestion = async ({ search }: { search: string }) => {
  const res = await axiosInstance2.get(`/v3/suggestion/${search}`);
  return res.data;
};

export const searchPrice = async ({
  article,
  brand,
  userId,
  provider = ""
}: {
  article: string;
  brand?: string | null;
  userId?: string;
  provider?: string;
}) => {
  const url = brand
    ? `/v3/search/${article}/${brand}`
    : `/v3/search/${article}`;

  const res = await axiosInstance2.get(url, {
    params: {
      uid: userId,
      provider
    }
  });
  return res.data;
};

export const getNomenclature = async (id: string) => {
  const params = {
    id
  };
  const res = await axiosInstance.get("/nomenclature.getOneClient", { params });
  return res.data;
};
