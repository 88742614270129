import { FC } from "react";

import { Button } from "components/Button";
import { useBreakpoint } from "hooks/useBreakpoint";

import styles from "./resetPasswordResult.module.scss";

interface IResetPassword {
  setShownModalResult: (shownModalResult: boolean) => void;
}

export const ResetPasswordResult: FC<IResetPassword> = ({
  setShownModalResult,
}) => {
  const { breakpoint } = useBreakpoint();
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>Восстановление пароля</div>
      <div className={styles.text}>
        На указанный E-mail будет отправлена ссылка на восстановление пароля
      </div>
      <Button
        onClick={() => setShownModalResult(false)}
        className={styles.button}
        breakpoint={breakpoint}
      >
        ОК
      </Button>
    </div>
  );
};
