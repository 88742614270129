import { FC } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import classNames from "classnames";
import { object, string, boolean, setLocale } from "yup";

import { Input } from "components/Input";
import { locale } from "validationLocale";
import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox";
import { FormGroup } from "components/FormGroup";
import { useBreakpoint } from "hooks/useBreakpoint";
import { TRegister } from "services/authApi";

import styles from "./privateForm.module.scss";

setLocale(locale);

const RegisterSchema = object().shape({
  email: string().email().required(),
  password: string().min(8, "Минимальная длина пароля 8 символов").required(),
  firstName: string().min(2).required(),
  lastName: string().min(2).required(),
  phone: string().min(17).required(),
  agreement: boolean().oneOf([true]),
});

interface IRegister {
  registerHandler: (values: TRegister, resetForm: () => void) => void;
}

export const PrivateForm: FC<IRegister> = ({ registerHandler }) => {
  const { breakpoint, isMobile } = useBreakpoint();
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        middleName: "",
        phone: "",
        type: 1,
        agreement: false,
      }}
      validationSchema={RegisterSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        registerHandler(values, resetForm);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={classNames(styles[breakpoint])}
        >
          <FormGroup title="Регистрационные данные">
            <Input
              id="email"
              type="email"
              label="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              className={styles.width50}
              error={errors.email && touched.email ? errors.email : null}
            />
            <Input
              id="password"
              type="password"
              label="Пароль"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              className={styles.width50}
              showPass
              error={
                errors.password && touched.password ? errors.password : null
              }
            />
          </FormGroup>

          <FormGroup title="Контактные данные">
            <Input
              id="lastName"
              label="Фамилия"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              className={styles.width50}
              error={
                errors.lastName && touched.lastName ? errors.lastName : null
              }
            />
            <Input
              id="firstName"
              label="Имя"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              className={styles.width50}
              error={
                errors.firstName && touched.firstName ? errors.firstName : null
              }
            />
            <Input
              id="middleName"
              label="Отчество"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.middleName}
              className={styles.width50}
              error={
                errors.middleName && touched.middleName
                  ? errors.middleName
                  : null
              }
            />
            <Input
              id="phone"
              type="tel"
              label="Телефон"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
              className={styles.width50}
              mask="+7(999) 999-99-99"
              error={errors.phone && touched.phone ? errors.phone : null}
            />
            <div className={styles.bottomBlock}>
              <Button
                type="submit"
                className={classNames(styles.width50, styles.enter)}
                breakpoint={breakpoint}
              >
                Зарегистрироваться
              </Button>
              <div className={styles.width50}>
                <Checkbox
                  id="agreement"
                  name="agreement"
                  onChange={() => setFieldValue("agreement", !values.agreement)}
                  checked={values.agreement}
                  error={
                    errors.agreement && touched.agreement && errors.agreement
                  }
                >
                  <span>
                    С <a
                      href="https://lider-truck.ru/agreement/"
                      target="_blank"
                      rel="noreferrer">пользовательским соглашением</a> ознакомлен
                  </span>
                </Checkbox>
              </div>
            </div>
            <div className={styles.login}>
              <div>
                Есть аккаунт? - <Link to="/login">Авторизация</Link>
              </div>
            </div>
          </FormGroup>
        </form>
      )}
    </Formik>
  );
};
