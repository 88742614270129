import React, { FC, ReactNode, Children, useState, useRef } from "react";
import classNames from "classnames";
import { useBreakpoint } from "hooks/useBreakpoint";

import { Transition } from "components/Transition";

import styles from "./tabs.module.scss";

interface ITabs {
  children: ReactNode[];
  tabs: string[];
  height?: number;
  className?: string;
}

export const Tabs: FC<ITabs> = ({ children, tabs, height, className }) => {
  const childElem = useRef(null);
  const { breakpoint } = useBreakpoint();
  const [selected, setSelected] = useState(0);

  const countChildren = Children.count(children);

  const renderContent = () => {
    if (countChildren === 1) return children;

    return Children.map(children, (child, i) => (
      <>{selected === i && <div className={styles.content}>{child}</div>}</>

      // <Transition key={i} shown={+selected === i}>
      //   <div className={styles.content}>{child}</div>
      // </Transition>
    ));
  };

  //ref={childElem}
  // useEffect(() => {
  //   const height = (childElem?.current as Node).getBoundingClientRect().height;

  //   console.log(height, "height");
  // }, [childElem]);

  const renderTabs = () => {
    return tabs.map((t, i) => (
      <button
        key={i}
        className={classNames(styles.tab, { [styles.active]: +selected === i })}
        onClick={() => setSelected(i)}
      >
        {t}
      </button>
    ));
  };

  return (
    <>
      <div className={classNames(styles.tabs, className, styles[breakpoint])}>
        {renderTabs()}
      </div>
      <div
        className={classNames(styles.items, styles[breakpoint])}
        style={{ height: `${height}px` }}
      >
        {renderContent()}
      </div>
    </>
  );
};
