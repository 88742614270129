import { FC } from "react";
import classNames from "classnames";

import { ButtonSmall } from "components/ButtonSmall";
import { ReactComponent as ArrowIcon } from "assets/img/svg/arrow.svg";

import { TBrand } from "../SearchPage";

import styles from "./selectBrand.module.scss";

interface ISelectBrand {
  brands: TBrand[];
  selectBrand: (brand: TBrand) => void;
  breakpoint?: string;
}

export const SelectBrand: FC<ISelectBrand> = ({ brands, selectBrand, breakpoint = "desktop" }) => {
  return <div className={classNames(styles.brandsBlock, styles[breakpoint])}>
    <div className={styles.brandsHead}>
      <div>Бренд</div>
      {breakpoint === "desktop" ?
        <>
          <div>Артикул</div>
          <div>Наименование</div>
        </>
        : <>
          <div>Описание</div>
          <div></div>
        </>
      }

      <div></div>
    </div>
    <div className={styles.brandsList}>
      {brands.map((item: TBrand) => (
        <div
          className={styles.brandItem}
          key={`${item.brand}_${item.partNumber}`}
          onClick={() => selectBrand(item)}
        >
          <div>{item.brand}</div>
          {breakpoint === "desktop" ?
            <>
              <div>{item.partNumber}</div>
              <div>{item.name}</div>
              <div>
                <ButtonSmall
                  onClick={() => selectBrand(item)}
                  className={styles.button}
                >
                  Цены и аналоги
                </ButtonSmall>
              </div></>
            :
            <>
              <div>
                <div>{item.partNumber}</div>
                <div className={styles.name}>{item.name}</div>
              </div>
              <div>
                <button
                  onClick={() => selectBrand(item)}
                  className={styles.buttonMobile}><ArrowIcon /></button>
              </div>
            </>
          }
        </div>
      ))}
    </div>
  </div>
}