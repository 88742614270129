import { FC, ReactNode, useEffect, useRef } from "react";
import { useSwipeable, UP, DOWN, SwipeEventData } from 'react-swipeable';
import classNames from "classnames";


import { CloseButton } from "components/CloseButton";
import { Transition } from "components/Transition";

import styles from "./filterBottom.module.scss";

interface IFileterBottom {
  children: ReactNode
  shown: boolean;
  setShown: (shownFilter: boolean) => void,
  breakpoint?: string;
}

export const FilterBottom: FC<IFileterBottom> = ({ children, shown, setShown, breakpoint = "desktop" }) => {

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const body = document.body;
    if (shown === true) {
      body.classList.add("noScroll");
    } else {
      body.classList.remove("noScroll");
    }
  }, [shown]);

  const handleSwiped = (eventData: SwipeEventData) => {
    if (ref.current) {

      if (breakpoint === "tablet") {

        if (eventData.absX > 170) {
          setShown(false);
        } else {
          ref.current.style.removeProperty("left");
        }
      } else {
        if (eventData.absY > 170) {
          setShown(false);
        } else {
          ref.current.style.removeProperty("bottom");
        }
      }

    }
  };

  const handleSwiping = (eventData: SwipeEventData) => {
    console.log(eventData);

    if (breakpoint === "tablet") {
      if (eventData.dir === "Right") {
        return;
      }

      if (ref.current) {
        ref.current.style.left = `-${eventData.absX}px`;
      }

    } else {
      if (eventData.dir === "Up") {
        return;
      }

      if (ref.current) {
        ref.current.style.bottom = `-${eventData.absY}px`;
      }
    }
  }

  const handlers = useSwipeable({
    onSwiped: handleSwiped,
    onSwiping: handleSwiping,
    onTouchStartOrOnMouseDown: (({ event }) => event.preventDefault()),
    touchEventOptions: { passive: false },
    preventScrollOnSwipe: true,
    trackMouse: true
  });

  return <Transition shown={shown}>
    <div>
      <div className={styles.overlay} onClick={() => setShown(false)} />
      <div className={classNames(styles.filter, styles[breakpoint])} ref={ref} >
        <div className={styles.filterTitle} {...handlers}>Фильтр</div>
        <CloseButton onClick={() => setShown(false)} />
        {children}
      </div>
    </div>
  </Transition>
}