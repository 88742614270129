import { FC, ReactNode } from "react";
import classNames from "classnames";

import styles from "./quantityIcon.module.scss";

interface IQuantityIcon {
  children: ReactNode;
  quantity: number;
  className?: string;
}

export const QuantityIcon: FC<IQuantityIcon> = ({
  children,
  quantity,
  className,
}) => {
  return (
    <div
      className={classNames(styles.delivery, className, {
        [styles.many]: quantity >= 10,
        [styles.mean]: quantity >= 5 && quantity < 10,
        [styles.few]: quantity > 0 && quantity < 5,
      })}
    >
      {children}
    </div>
  );
};
