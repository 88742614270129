import { FC, ChangeEvent, useState, useEffect } from "react";
import classNames from "classnames";

import { Checkbox } from "components/Checkbox";
import { sortResult } from "utils/sort";
import { priceFormat } from "utils/format";
import { InputFilter } from "components/InputFilter";

import {
  TBrandSearchArray,
  TProductSearch,
  TProductSearchArray,
  TProductSearchValue,
} from "../SearchPage";
import { TOffer } from "../Offer";

import styles from "./filter.module.scss";

export type TFilter = {
  inStock: boolean;
  brands: string[];
};

interface IFilter {
  data: {};
  onFilter: (filter: TFilter) => void;
}

export const Filter: FC<IFilter> = ({ data, onFilter }) => {
  const [search, setSearch] = useState<string>("");
  const [shown, setShown] = useState<boolean>(false);
  const [filter, setFilter] = useState<TFilter>({
    inStock: false,
    brands: [],
  });
  const [brands, setBrands] = useState<TBrandSearchArray[]>([]);
  const [inStock, setInStock] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(true);

  useEffect(() => {
    if (!data) return;
    const brands: TBrandSearchArray[] = Object.entries<TProductSearch>(data);
    setBrands(brands);
  }, [data]);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "all") {
      setFilter((prev) => {
        return { ...prev, brands: [] };
      });
      setSelectAll(e.target.checked);
      return;
    }

    const index = filter.brands.indexOf(e.target.value);

    const newFilterBrands = [...filter.brands];
    if (e.target.checked === false) {
      newFilterBrands.splice(index, 1);
    } else {
      newFilterBrands.push(e.target.value);
    }

    if (e.target.value !== "all") {
      if (newFilterBrands.length === 0) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    }

    setFilter((prev) => {
      return { ...prev, brands: newFilterBrands };
    });
  };

  useEffect(() => {
    setFilter((prev) => {
      return { ...prev, inStock };
    });

    if (!data) return;

    const brandsInStock = () =>
      Object.entries<TProductSearch>(data).filter(
        (itemBrand: TBrandSearchArray) => {
          const filtered = Object.entries<TProductSearchValue>(
            itemBrand[1]
          ).filter((itemArticle: TProductSearchArray) => {
            if (
              inStock === true &&
              !itemArticle[1].offer.find(
                (offer: TOffer) => offer.inStock === true
              )
            ) {
              return false;
            }
            return true;
          });
          return filtered.length > 0 ? true : false;
        }
      );

    setBrands(brandsInStock());
  }, [inStock]);

  useEffect(() => {
    onFilter(filter);
  }, [filter]);

  useEffect(() => {
    if (!data) return;
    const filtered: TBrandSearchArray[] = Object.entries<TProductSearch>(
      data
    ).filter((item: TBrandSearchArray) =>
      item[0].includes(search.toUpperCase())
    );
    setBrands(filtered);
  }, [search]);

  const getMinPrice = (item: TBrandSearchArray) => {
    const output: TOffer[] = [];
    for (const article of Object.keys(item[1])) {
      output.push(...item[1][article].offer);
    }
    const sorted = sortResult({ sort: "default", items: output });
    return sorted.length > 0 ? sorted[0].price : 0;
  };

  return (
    <div className={styles.filter}>
      <div className={styles.item}>
        <Checkbox
          id="inStock"
          onChange={(e) => setInStock(e.target.checked)}
          checked={inStock}
        >
          <div className={styles.label}>В наличии</div>
        </Checkbox>
      </div>
      <div className={styles.title}>Производители</div>
      <div className={styles.search}>
        <InputFilter
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          placeholder="Поиск"
        />
      </div>
      {brands.length > 0 && (
        <>
          <div className={styles.item}>
            <Checkbox
              id="all"
              onChange={changeHandler}
              checked={selectAll}
              value="all"
            >
              <div className={styles.label}>Все</div>
            </Checkbox>
          </div>
          {brands.slice(0, shown ? brands.length : 6).map((item) => (
            <div className={styles.item} key={item[0]}>
              <Checkbox
                id={item[0]}
                onChange={changeHandler}
                checked={filter.brands.indexOf(item[0]) > -1}
                value={item[0]}
              >
                <div className={styles.label}>
                  {item[0]}
                  <div className={styles.fromPrice}>
                    от {priceFormat(getMinPrice(item))} р.
                  </div>
                </div>
              </Checkbox>
            </div>
          ))}
        </>
      )}

      {brands.length > 6 && (
        <div className={classNames(styles.showMore, { [styles.shown]: shown })}>
          <button onClick={() => setShown((prev) => !prev)}>
            {shown ? "Скрыть" : "Показать все"}
          </button>
        </div>
      )}
    </div>
  );
};
