import {
  FC,
  ChangeEvent,
  useState,
  useEffect,
  InputHTMLAttributes,
  KeyboardEventHandler,
} from "react";
import InputMask, { Props as InputMaskProps } from "react-input-mask";
import classNames from "classnames";

import { ReactComponent as EyeCloseIcon } from "./eyeClose.svg";

import styles from "./input.module.scss";

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  onChange: (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => void;
  id: string;
  value: string;
  label: string;
  type?: string;
  className?: string;
  maxLength?: number;
  disabled?: boolean;
  onBlur?: (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => void;
  onFocus?: () => void;
  error?: string | null;
  mask?: InputMaskProps["mask"];
  showPass?: boolean;
  ghost?: string;
  onKeyDown?: () => void;
}

export const Input: FC<IInput> = ({
  onChange,
  id,
  value,
  label,
  type = "text",
  className,
  maxLength,
  onBlur,
  onFocus,
  error,
  mask,
  showPass,
  onKeyDown
}) => {
  const [filled, setFilled] = useState(value ? true : false);
  const [shownPassword, setShownPassword] = useState(false);

  useEffect(() => {
    setFilled(value ? true : false);
  }, [value]);

  const focusHandler = () => {
    setFilled(true);
    if (onFocus) {
      onFocus();
    }
  };
  const onBlur1 = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFilled(value ? true : false);
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div
        className={classNames(
          styles.input,
          { [styles.filled]: filled },
          { [styles.error]: error }
        )}
      >
        <span className={styles.labelBg}>{label}</span>
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
        {type === "textarea" ? (
          <textarea
            id={id}
            onChange={onChange}
            value={value}
            onFocus={focusHandler}
            onBlur={onBlur1}
          />
        ) : (
          <>
            {mask ? (
              <InputMask
                id={id}
                type={type}
                onChange={onChange}
                value={value}
                maxLength={maxLength}
                onFocus={focusHandler}
                onBlur={onBlur1}
                autoComplete={type === "password" ? "new-password" : undefined}
                mask={mask}
                maskChar={null}
                onKeyDown={onKeyDown}
              />
            ) : (
              <input
                id={id}
                type={shownPassword ? "text" : type}
                onChange={onChange}
                value={value}
                maxLength={maxLength}
                onFocus={focusHandler}
                onBlur={onBlur1}
                autoComplete={type === "password" ? "new-password" : undefined}
                onKeyDown={onKeyDown}
              />
            )}
          </>
        )}
        {showPass && (
          <button
            type="button"
            onClick={() => setShownPassword((prev) => !prev)}
            className={classNames({ [styles.show]: shownPassword })}
          >
            <EyeCloseIcon />
          </button>
        )}
      </div>
      {error && <span className={styles.errorText}>{error}</span>}
    </div>
  );
};
