import { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import { Formik } from "formik";
import { object, string, setLocale } from "yup";
import { toast } from "react-toastify";

import { BreadCrumbs } from "layout/BreadCrumbs";
import { useBreakpoint } from "hooks/useBreakpoint";
import { Body } from "layout/Body";
import { PageHeader } from "components/PageHeader";
import { MainBlock } from "layout/MainBlock";
import { RightSidebar } from "layout/RightSidebar";
import { locale } from "validationLocale";
import { Input } from "components/Input";
import { Button } from "components/Button";
import { TRequestReconciliationAct, requestReconciliationAct } from "services/balanceApi";
import { Spinner2 } from "components/Spinner2";
import { DateRange } from "components/DateRange";
import { useUser } from "context/UserContext";

import styles from "./reconciliationAct.module.scss";

setLocale(locale);

const RequestSchema = object().shape({
  email: string().email().required(),
  dateStart: string().required(),
  dateEnd: string().required(),
});

export const ReconciliationActPage: FC = () => {
  const [loading, setLoading] = useState(false);
  const { breakpoint, isMobile } = useBreakpoint();
  const { user } = useUser();

  const requestHandler = (
    values: TRequestReconciliationAct,
    resetForm: () => void,
  ) => {
    setLoading(true);
    requestReconciliationAct(values)
      .then((res) => {
        resetForm();
        toast.success("Запрос успешно отправлен");
      })
      .catch((e) => {
        alert(e.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return <>
    <BreadCrumbs data={[{ title: "Запросить акт сверки" }]} />
    <Helmet>
      <title>Запросить акт сверки</title>
    </Helmet>
    <div className={classNames(styles.reconciliationAct, styles[breakpoint])}>
      <Body>
        <PageHeader title="Запросить акт сверки" className={styles.pageHeader} />
        {loading && <Spinner2 overlay />}
        <MainBlock>

          <Formik
            initialValues={{
              email: user.email || "",
              dateStart: "",
              dateEnd: "",
            }}
            validationSchema={RequestSchema}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              requestHandler(values, resetForm);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <form
                onSubmit={handleSubmit}
                className={classNames(styles.form, styles[breakpoint])}
              >
                <Input
                  id="email"
                  type="email"
                  label="Email, на который придет запрошенный акт сверки"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  className={styles.width50}
                  error={errors.email && touched.email
                    ? (errors.email as string)
                    : null}
                />

                <DateRange
                  setDtStart={(val: string) => setFieldValue("dateStart", val)}
                  setDtEnd={(val: string) => setFieldValue("dateEnd", val)}
                  className={styles.dateRange}
                />

                <Button
                  type="submit"
                  className={classNames(styles.width50, styles.enter)}
                  breakpoint={breakpoint}
                >
                  Запросить
                </Button>

              </form>
            )}
          </Formik>
        </MainBlock>

      </Body>
      {!isMobile && <RightSidebar />}
    </div>
  </>;
}