import { FC, ReactNode, useEffect } from "react";
import ReactModal, { Styles } from "react-modal";
import { useBreakpoint } from "hooks/useBreakpoint";
import classNames from "classnames";

import { CloseButton } from "components/CloseButton";

import styles from "./rmodal.module.scss";

interface IModalComponent {
  children: ReactNode;
  shown: boolean;
  setShown: (shown: boolean) => void;
  className?: string;
  onClose?: () => void;
}

export const RModal: FC<IModalComponent> = ({
  children,
  className,
  shown,
  setShown,
  onClose
}) => {
  const { isMobile, breakpoint } = useBreakpoint();

  const customStyles: Styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "rgba(37, 37, 56,.7)",
      zIndex: 9999,
      overflowY: "auto",
    },
  };

  return (
    <ReactModal
      isOpen={shown}
      onRequestClose={() => {
        setShown(false);
        if (onClose) {
          onClose();
        }
      }}
      ariaHideApp={false}
      style={customStyles}
      className={classNames(styles.modal, styles[breakpoint])}
    >
      <div className={classNames(styles.content, className)}>
        <div>{children}</div>
        <CloseButton onClick={() => {
          setShown(false);
          if (onClose) {
            onClose();
          }
        }} />
      </div>

    </ReactModal>
  );
};
