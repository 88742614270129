import { FC } from "react";
import classNames from "classnames";
import { Link, NavLink, useNavigate } from "react-router-dom";

import { Transition } from "components/Transition";
import { useBreakpoint } from "hooks/useBreakpoint";
import { IRoute, menuItems } from "routes";
import { useUser } from "context/UserContext";
import { cut } from "utils/cut";

import styles from "./sidebar.module.scss";

interface ISidebar {
  shownMenu: boolean;
  setShownMenu: (shownMenu: boolean) => void;
}

export const Sidebar: FC<ISidebar> = ({ shownMenu, setShownMenu }) => {
  const { breakpoint, isMobile, isDesktop } = useBreakpoint();
  const { user, setUser, setAuthorized } = useUser();
  const navigate = useNavigate();

  const logout = () => {
    setAuthorized(false);
    localStorage.removeItem("clientAccessToken");
    localStorage.removeItem("clientRefreshToken");

    setUser({
      addresses: [],
      lastName: "",
      firstName: "",
      middleName: "",
      phone: "",
      email: "",
      type: 0,
      markup: 0,
      delayDayCount: 0,
      creditSumMax: 0,
      orgNameShort: "",
      balance: 0,
    });

    navigate({ pathname: "/login" });
  };

  return (
    <>
      <div
        className={classNames(styles.wrapper, styles[breakpoint], {
          [styles.shown]: shownMenu,
        })}
      >
        {!isMobile && (
          <button
            className={styles.close}
            onClick={() => setShownMenu(false)}
          />
        )}
        <div className={styles.overflow}>
          <nav className={styles.nav}>
            <div className={styles.profile}>
              <div>
                <div>
                  <div className={styles.icon}>{user.firstName.charAt(0)}</div>
                </div>
                <Link to="/profile" onClick={() => setShownMenu(false)}>
                  <div className={styles.userName}>{cut(user.firstName, 7, 7)}</div>
                  <div className={styles.settings}>Настройки профиля</div>
                </Link>
              </div>
              {!isDesktop &&
                <button onClick={logout} className={styles.logout}>Выход</button>
              }

            </div>
            <ul className={styles.nav}>
              {menuItems.map((item: IRoute) => (
                <li className={styles.navItem} key={item.path}>
                  <NavLink
                    to={item.path}
                    className={({ isActive }) =>
                      isActive ? styles.active : ""
                    }
                    onClick={() => setShownMenu(false)}
                  >
                    {item.icon && <item.icon />}
                    {item.title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>
      {!isMobile && (
        <Transition shown={shownMenu}>
          <div className={styles.overlay} onClick={() => setShownMenu(false)} />
        </Transition>
      )}
    </>
  );
};
