import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import { toast } from "react-toastify";

import { useBreakpoint } from "hooks/useBreakpoint";
import { ProductInfo, TProduct } from "components/ProductInfo";
import { sortResult } from "utils/sort";
import { inFavorite } from "utils/cart";
import { FavoriteButton } from "components/FavoriteButton";
import { addFavorite, getFavorite, removeFavorite } from "services/favoriteApi";
import { useCart } from "context/CartContext";

import { Offer, TOffer } from "../Offer";
import { TFilter } from "../Filter";

import styles from "./product.module.scss";

interface IProduct {
  article: string;
  brand: string;
  product: TProduct;
  filter: TFilter;
  sort?: string;
  shownMore?: number;
  setShownMore: (
    article: string,
    brand: string,
    shownMore: number
  ) => void;
}

export const Product: FC<IProduct> = ({
  article,
  brand,
  product,
  filter,
  sort,
  shownMore = 2,
  setShownMore
}) => {
  const { breakpoint } = useBreakpoint();
  const [sorted, setSorted] = useState<TOffer[]>([]);
  const { favorite, setFavorite } = useCart();

  useEffect(() => {
    setSorted(sortResult({ sort, items: product.offer }));
  }, []);

  useEffect(() => {
    if (sort) {
      setSorted(sortResult({ sort, items: product.offer }));
    }
  }, [sort]);

  const offersFiltered = () => {
    return sorted.filter((item: TOffer) => {
      if (filter.inStock === true && item.inStock !== true) {
        return null;
      }
      return item;
    });
  };

  if (!offersFiltered().length) {
    return null;
  }

  const addFavoriteHandler = (product: TProduct) => {
    if (!product.nomenclatureId) return;
    addFavorite({ nomenclature: product.nomenclatureId })
      .then((res) => setFavorite([...favorite, res]))
      .catch((e) => toast.error(e.response.data.message))
      .finally();
  };

  const getAndSetFavorite = () => {
    getFavorite()
      .then(setFavorite)
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => { });
  };

  const removeFavoriteHandler = (id?: string) => {
    if (!id) return;
    removeFavorite(id)
      .then(getAndSetFavorite)
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => { });
  };

  const favoriteButtonRender = (product: TProduct) => {
    if (!product.nomenclatureId) return <div className={styles.noFavorite} />;

    const favoriteId = inFavorite(favorite, product);

    if (favoriteId !== null) {
      return (
        <FavoriteButton
          onClick={() => removeFavoriteHandler(favoriteId)}
          className={styles.favoriteButton}
          active
        />
      );
    }

    return (
      <FavoriteButton
        onClick={() => {
          addFavoriteHandler(product);
        }}
        className={styles.favoriteButton}
      />
    );
  };

  return (
    <div className={classNames(styles.product, styles[breakpoint])}>
      <div className={styles.productInfo}>
        <ProductInfo
          article={article}
          brand={brand}
          name={product?.name}
          product={product}
          image={product?.image || ""}
          nomenclatureId={product?.nomenclatureId || ""}
        />
      </div>
      <div className={styles.offers}>
        <>
          {offersFiltered()
            .slice(0, shownMore)
            .map((offer: TOffer) => (
              <Offer
                name={product?.name}
                article={article}
                brand={brand}
                offer={offer}
                key={offer.id}
              />
            ))}
          {offersFiltered().length > 2 &&
            offersFiltered().length - shownMore > 0 && (
            <div className={styles.showMore}>
              <button onClick={() => setShownMore(article, brand, offersFiltered().length)}>
                Еще предложения ({offersFiltered().length - shownMore})
              </button>
            </div>
          )}
        </>
      </div>
      <div className={styles.favorite}>{favoriteButtonRender(product)}</div>
    </div>
  );
};
