import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import { toast } from "react-toastify";

import { useBreakpoint } from "hooks/useBreakpoint";
import { ButtonSmall } from "components/ButtonSmall";
import { ReactComponent as CartIcon } from "assets/img/svg/cart.svg";
import { QuantityIcon } from "components/QuantityIcon";
import { FavoriteButton } from "components/FavoriteButton";
import { priceFormat } from "utils/format";
import { Counter } from "components/Counter";
import { useCart } from "context/CartContext";
import { cartAdd } from "services/cartApi";
import { inCart } from "utils/cart";
import { Spinner3 } from "components/Spinner3";

import { ProviderPopup } from "../ProviderPopup";

import styles from "./offer.module.scss";

export type TOffer = {
  id: string;
  article: string;
  brand: string;
  name: string;
  providerId: string;
  priceListId: string;
  deliveryTime: string;
  deliveryTimeText: string;
  probability: number;
  exist: number;
  price: number;
  inStock: boolean;
  nomenclatureId?: string;
};

export interface IOffer {
  article: string;
  brand: string;
  name: string;
  offer: TOffer;
}

export const Offer: FC<IOffer> = ({ name, article, brand, offer }) => {
  const { breakpoint, isDesktop } = useBreakpoint();
  const [quantity, setQuantity] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const { setCart, cart, setAdded, setCartLoading, favorite, setFavorite } =
    useCart();

  const cartHandler = () => {
    setLoading(true);
    cartAdd({
      ...offer,
      article: article,
      brand: brand,
      name: name,
      count: quantity,
    })
      .then((res) => {
        setCart(res.cart);
        setAdded({
          ...offer,
          id: res.added?.id,
          article: article,
          brand: brand,
          name: name,
          quantity,
          relatedProducts: res.added?.nomenclature?.relatedProducts,
        });

      })
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div
      className={classNames(styles.offer, styles[breakpoint], {
        [styles.inStock]: offer.inStock,
        [styles.inTransit]: false
      })}
      provider-id={offer.providerId}
    >
      {isDesktop ? (
        <>
          <QuantityIcon quantity={offer.exist} className={styles.quantityIcon}>
            <ProviderPopup
              percent={offer.probability}
              className={styles.providerPopup}
            />
            {offer.inStock ? "На складе" : offer.deliveryTimeText}
          </QuantityIcon>
          <div>{offer.exist} шт.</div>
          <div className={styles.price}>{priceFormat(offer.price)} р.</div>
          <div className={styles.cart}>
            <div className={styles.inCart}>

              {inCart(cart, article, brand, offer?.providerId, offer?.priceListId) > 0 && (
                <>
                  <CartIcon />
                  {inCart(cart, article, brand, offer?.providerId, offer?.priceListId)}
                </>
              )}
            </div>
            <Counter
              max={offer.exist}
              quantity={quantity}
              setQuantity={setQuantity}
              breakpoint={breakpoint}
            />
            <ButtonSmall onClick={cartHandler} breakpoint={breakpoint}>
              {loading ? <Spinner3 /> : "Купить"}
            </ButtonSmall>
          </div>
        </>
      ) : (
        <>
          <div className={styles.existCol}>
            <div className={styles.quantity}>{offer.exist} шт.</div>
            <div className={styles.inCart}>
              {inCart(cart, article, brand, offer?.providerId, offer?.priceListId) > 0 && (
                <>
                  <CartIcon />
                  {inCart(cart, article, brand, offer?.providerId, offer?.priceListId)}
                </>
              )}
            </div>
          </div>
          <QuantityIcon quantity={offer.exist}>
            <div>
              <div className={styles.price}>{priceFormat(offer.price)} р.</div>
              <div className={styles.deliveryText}>
                {offer.inStock ? "На складе" : offer.deliveryTimeText}
              </div>
            </div>
          </QuantityIcon>
          <div className={styles.buyBlock}>
            <Counter
              max={offer.exist}
              quantity={quantity}
              setQuantity={setQuantity}
              breakpoint={breakpoint}
            />
            <ButtonSmall onClick={cartHandler} breakpoint={breakpoint}>
              {loading ? <Spinner3 /> : <CartIcon />}
            </ButtonSmall>
          </div>
        </>
      )}
    </div>
  );
};
