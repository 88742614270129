import { FC } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { Card } from "components/Card";
import { ReactComponent as HeartIcon } from "assets/img/svg/heart.svg";
import { useBreakpoint } from "hooks/useBreakpoint";

import styles from "./favorite.module.scss";

export const FavoriteCard: FC = () => {
  const { breakpoint } = useBreakpoint();
  return <Card
    className={classNames(styles.favorite, styles[breakpoint])}
    breakpoint={breakpoint}
  >
    <Link to="/favorite">
      <div className={styles.top}>
        <HeartIcon className={styles.heartIcon} />
        <span>Избранное</span>
      </div>
      {breakpoint === "desktop" &&
        <div className={styles.bottom}>Добавляйте в избранное, чтоб не потерять</div>
      }
    </Link>
  </Card>;
};