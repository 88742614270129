import { FC } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox";
import { object, string, boolean, setLocale } from "yup";
import { Input } from "components/Input";
import { locale } from "validationLocale";
import { useBreakpoint } from "hooks/useBreakpoint";
import { FormGroup } from "components/FormGroup";

import { TRegister } from "services/authApi";

import styles from "./legalForm.module.scss";

setLocale(locale);

const RegisterSchema = object().shape({
  email: string().email().required(),
  password: string().min(8, "Минимальная длина пароля 8 символов").required(),
  firstName: string().min(2).required(),
  phone: string().min(17).required(),
  orgInn: string().min(9).max(12).required(),
  orgNameShort: string().min(3).required(),
  orgName: string().min(3).required(),
  orgAddress: string().min(3).required(),
  orgKpp: string().min(3).required(),
  orgOgrn: string().min(3).required(),
  orgOkpo: string().min(3).required(),
  orgRs: string().min(3).required(),
  orgKs: string().min(3).required(),
  orgBik: string().min(3).required(),
  orgBank: string().min(3).required(),
  genDir: string().min(3).required(),
  agreement: boolean().oneOf([true]),
});

interface IRegister {
  registerHandler: (values: TRegister, resetForm: () => void) => void;
}

export const LegalForm: FC<IRegister> = ({ registerHandler }) => {
  const { breakpoint, isMobile } = useBreakpoint();
  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        firstName: "",
        phone: "",
        orgInn: "",
        orgNameShort: "",
        orgName: "",
        orgAddress: "",
        orgKpp: "",
        orgOgrn: "",
        orgOkpo: "",
        orgRs: "",
        orgKs: "",
        orgBik: "",
        orgBank: "",
        genDir: "",
        type: 2,
        agreement: false,
      }}
      validationSchema={RegisterSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        registerHandler(values, resetForm);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form
          onSubmit={handleSubmit}
          className={classNames(styles[breakpoint])}
        >
          <FormGroup title="Регистрационные данные">
            <Input
              id="email"
              type="email"
              label="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              className={styles.width50}
              error={errors.email && touched.email ? errors.email : null}
            />
            <Input
              id="password"
              type="password"
              label="Пароль"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              className={styles.width50}
              showPass
              error={
                errors.password && touched.password ? errors.password : null
              }
            />
          </FormGroup>

          <FormGroup title="Контактные данные">
            <Input
              id="firstName"
              label="Контактное лицо"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              className={styles.width50}
              error={
                errors.firstName && touched.firstName ? errors.firstName : null
              }
            />
            <Input
              id="phone"
              type="tel"
              label="Телефон"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone}
              className={styles.width50}
              error={errors.phone && touched.phone ? errors.phone : null}
              mask="+7(999) 999-99-99"
            />
          </FormGroup>
          <FormGroup title="Данные компании">
            <Input
              id="orgInn"
              label="ИНН"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgInn}
              className={styles.width50}
              error={errors.orgInn && touched.orgInn ? errors.orgInn : null}
            />
            <Input
              id="orgNameShort"
              label="Краткое наименование"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgNameShort}
              className={styles.width50}
              error={
                errors.orgNameShort && touched.orgNameShort
                  ? errors.orgNameShort
                  : null
              }
            />
            <Input
              id="orgName"
              label="Полное наименование организации"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgName}
              className={styles.width100}
              error={errors.orgName && touched.orgName ? errors.orgName : null}
            />
            <Input
              id="orgAddress"
              label="Юридический адрес организации"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgAddress}
              className={styles.width100}
              error={
                errors.orgAddress && touched.orgAddress
                  ? errors.orgAddress
                  : null
              }
            />
            <Input
              id="orgKpp"
              label="КПП"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgKpp}
              className={styles.width50}
              error={errors.orgKpp && touched.orgKpp ? errors.orgKpp : null}
            />
            <Input
              id="orgOgrn"
              label="ОГРН"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgOgrn}
              className={styles.width50}
              error={errors.orgOgrn && touched.orgOgrn ? errors.orgOgrn : null}
            />
            <Input
              id="orgOkpo"
              label="ОКПО"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgOkpo}
              className={styles.width50}
              error={errors.orgOkpo && touched.orgOkpo ? errors.orgOkpo : null}
            />
            <Input
              id="orgRs"
              label="Расчетный счет"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgRs}
              className={styles.width50}
              error={errors.orgRs && touched.orgRs ? errors.orgRs : null}
            />
            <Input
              id="orgKs"
              label="Корр. счет"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgKs}
              className={styles.width50}
              error={errors.orgKs && touched.orgKs ? errors.orgKs : null}
            />
            <Input
              id="orgBik"
              label="БИК"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgBik}
              className={styles.width50}
              error={errors.orgBik && touched.orgBik ? errors.orgBik : null}
            />
            <Input
              id="orgBank"
              label="Банк"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.orgBank}
              className={styles.width50}
              error={errors.orgBank && touched.orgBank ? errors.orgBank : null}
            />
            <Input
              id="genDir"
              label="ФИО генерального директора"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.genDir}
              className={styles.width50}
              error={errors.genDir && touched.genDir ? errors.genDir : null}
            />
            <div className={styles.bottomBlock}>
              <Button
                type="submit"
                className={classNames(styles.width50, styles.enter)}
                disabled={isSubmitting}
                breakpoint={breakpoint}
              >
                Зарегистрироваться
              </Button>
              <div className={styles.width50}>
                <Checkbox
                  id="agreement"
                  name="agreement"
                  onChange={() => setFieldValue("agreement", !values.agreement)}
                  checked={values.agreement}
                  error={
                    errors.agreement && touched.agreement && errors.agreement
                  }
                >
                   С <a href="https://lider-truck.ru/agreement/"
                    target="_blank"
                    rel="noreferrer">пользовательским соглашением</a> ознакомлен
                </Checkbox>
              </div>
            </div>
            <div className={styles.login}>
              <div>
                Есть аккаунт? - <Link to="/login">Авторизация</Link>
              </div>
            </div>
          </FormGroup>
        </form>
      )}
    </Formik>
  );
};
