import { FC, useState, useEffect } from "react";
import { toast } from "react-toastify";
import classNames from "classnames";

import { Button } from "components/Button";
import { Checkbox } from "components/Checkbox";
import { useUser } from "context/UserContext";
import { addAddress, removeAddress, setDefaultAddress } from "services/userApi";
import { Input } from "components/Input";
import { Transition } from "components/Transition";
import { useBreakpoint } from "hooks/useBreakpoint";
import { RModal } from "components/RModal";

import { ReactComponent as PlusIcon } from "assets/img/svg/plus.svg";
import { ReactComponent as RemoveIcon } from "assets/img/svg/trash.svg";

import { AddAddressModal } from "./AddAddressModal";

import styles from "./addresses.module.scss";

export type TAddress = {
  id: string;
  address: string;
  main: boolean;
};

export interface IAddresses {
  userAddresses: TAddress[];
}

export const Addresses: FC<IAddresses> = ({ userAddresses }) => {
  const { breakpoint } = useBreakpoint();
  const [address, setAddress] = useState("");
  const [addresses, setAddresses] = useState<TAddress[]>(userAddresses);
  const [loading, setLoading] = useState(false);
  const [shownAddAddress, setShownAddAddress] = useState(false);

  const removeAddressHandler = (id: string) => {
    setLoading(true);
    removeAddress({ id })
      .then((res) => {
        setAddresses(res);
        toast.success("Адрес успешно удален");
      })
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => setLoading(false));
  };

  const setDefaultAddressHandler = (id: string) => {
    const newAddresses = [...addresses];
    setAddresses(
      newAddresses.map((address: TAddress) => {
        return { ...address, main: false };
      })
    );

    setDefaultAddress({ id })
      .then((res) => {
        setAddresses(res);
        toast.success("Адрес по умолчанию установлен");
      })
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => setLoading(false));
  };

  return (
    <div className={classNames(styles[breakpoint])}>
      <div className={styles.title}>Адреса доставки</div>

      <div className={styles.subtitle}>
        Вы можете добавить несколько адресов доставки и указывать их при
        оформлении заказа
      </div>

      <div>
        {addresses.map((address: TAddress) => (
          <div className={styles.addressItem} key={address.id}>
            <div className={styles.addressText}>
              {address.address}
              <Checkbox
                id={address.id}
                checked={address.main}
                onChange={() => setDefaultAddressHandler(address.id)}
                className={styles.mainAddress}
              >
                Использовать как основной
              </Checkbox>
            </div>

            <div>
              <button
                className={styles.remove}
                onClick={() => removeAddressHandler(address.id)}
              >
                <RemoveIcon />
              </button>
            </div>
          </div>
        ))}
      </div>

      <div>
        <Button
          className={styles.showForm}
          onClick={() => setShownAddAddress(true)}
          breakpoint={breakpoint}
        >
          <PlusIcon />
          Добавить адрес
        </Button>
      </div>
      <RModal shown={shownAddAddress} setShown={setShownAddAddress}>
        <AddAddressModal
        setAddresses={setAddresses}
        setShownAddAddress={setShownAddAddress}
        breakpoint={breakpoint}
        />
      </RModal>
    </div>
  );
};
