import { FC, useState, useEffect } from "react";
import classNames from "classnames";

import { Input } from "components/Input";
import { Button } from "components/Button";
import { ReactComponent as PlusIcon } from "assets/img/svg/plus.svg";
import { addAddress } from "services/userApi";
import { toast } from "react-toastify";
import { TAddress } from "pages/Profile/Addresses";
import { Spinner2 } from "components/Spinner2";

import styles from "./selectAddress.module.scss";

interface ISelectAddress {
  addresses: TAddress[];
  setShownAddressModal: (shownAddressModal: boolean) => void;
  onSelect: (address: TAddress | null) => void;
  breakpoint?: string;
}

export const SelectAddress: FC<ISelectAddress> = ({
  addresses,
  setShownAddressModal,
  onSelect,
  breakpoint = "desktop"
}) => {
  const [selected, setSelected] = useState<TAddress | null>(null);
  const [address, setAddress] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<TAddress[]>([]);

  useEffect(() => {
    setItems(addresses);
  }, [addresses]);

  const addAddressHandler = () => {
    if (!address) return;
    setLoading(true);

    addAddress({ address })
      .then((res) => {
        setItems(res);
        setAddress("");
        toast.success("Адрес успешно добавлен");
      })
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className={classNames(styles.wrapper, styles[breakpoint])}>
      {loading && <Spinner2 overlay />}
      <div className={styles.title}>Адрес доставки</div>
      <div className={styles.subTitle}>Выберите адрес доставки</div>
      <div className={styles.addressesList}>
        {items.map((address) => (
          <div
            key={address.id}
            className={classNames(styles.addressItem, {
              [styles.active]: address.id === selected?.id
            })}
            onClick={() => setSelected(address)}
          >
            <div className={styles.radio} />
            {address.address}
          </div>
        ))}
      </div>
      <div className={styles.addAddressBlock}>
        {breakpoint === "desktop" &&
          <div className={styles.addAddressTitle}>Добавить новый адрес</div>
        }
        <div className={styles.inputGroup}>
          <Input
            id="address"
            label="Адрес"
            onChange={(e) => setAddress(e.target.value)}
            value={address}
            className={styles.input}
          />
          <Button
            className={styles.addButton}
            onClick={addAddressHandler}
            breakpoint={breakpoint}
          >
            <PlusIcon />
            Добавить
          </Button>
        </div>
        <div className={styles.closeResultBlock}>
          <Button
            onClick={() => {
              onSelect(selected);
              setShownAddressModal(false);
            }}
            className={styles.closeButton}
            disabled={!selected}
            breakpoint={breakpoint}
          >
            ОК
          </Button>
        </div>
      </div>
    </div>
  );
};
