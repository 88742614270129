import { FC, useState } from "react";
import { object, string, boolean, setLocale } from "yup";
import classNames from "classnames";
import { Formik } from "formik";
import { locale } from "validationLocale";
import { TResetPassword, resetPassword } from "services/userApi";
import { Link } from "react-router-dom";

import { Button } from "components/Button";
import { Input } from "components/Input";
import { RModal } from "components/RModal";
import { ResetPasswordResult } from "pages/Login/ResetPasswordResult";
import { useBreakpoint } from "hooks/useBreakpoint";
import { BreadCrumbs } from "layout/BreadCrumbs";
import { AuthWrapper } from "components/AuthWrapper";
import { ReactComponent as ArrowIcon } from "assets/img/svg/arrow.svg";

import styles from "./passwordRecovery.module.scss";

setLocale(locale);

const PasswordRecoverySchema = object().shape({
  email: string().email().required(),
});

export const PasswordRecoveryPage: FC = () => {
  const { breakpoint, isMobile } = useBreakpoint();
  const [shownModalResult, setShownModalResult] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const resetPasswordHandler = (
    values: TResetPassword,
    resetForm: () => void
  ) => {
    setLoading(true);

    setError("");

    resetPassword(values)
      .then((res) => {
        setShownModalResult(true);
        setEmail("");
        resetForm();
      })
      .catch((e) => {
        setError("Указанный E-mail не найден в базе");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <BreadCrumbs
        data={[
          { title: "Восстановление пароля" },
        ]}
      />
      <AuthWrapper>
        <div className={classNames(styles.wrapper, styles[breakpoint])}>
          <div className={styles.title}>Введите E-mail</div>
          <div className={styles.subTitle}>
            На указанный e-mail будет отправлена ссылка на восстановление пароля
          </div>
          <div className={styles.error}>{error}</div>

          <Formik
            initialValues={{
              email,
            }}
            validationSchema={PasswordRecoverySchema}
            onSubmit={(values, { resetForm }) => {
              setEmail(values.email);
              resetPasswordHandler(values, resetForm);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <Input
                  id="email"
                  type="email"
                  label="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  className={styles.input}
                  error={errors.email && touched.email ? errors.email : null}
                />
                <Button
                  type="submit"
                  className={styles.enter}
                  breakpoint={breakpoint}
                >
                  Восстановить пароль
                </Button>
                <div className={styles.register}>
                  <div>
                    Нет аккаунта? - <Link to="/register">Регистрация</Link>
                  </div>
                </div>
                <RModal shown={shownModalResult} setShown={setShownModalResult}>
                  <ResetPasswordResult setShownModalResult={setShownModalResult} />
                </RModal>
              </form>
            )}
          </Formik>
          <Link to="/login" className={styles.authLink}><ArrowIcon/>Авторизация</Link>
        </div>
      </AuthWrapper>
    </>
  );
};
