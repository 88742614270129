import { FC, ReactNode, useState, useEffect } from "react";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Header } from "layout/Header";
import { BreadCrumbs, TBreadCrumbsItem } from "layout/BreadCrumbs";
import { Sidebar } from "layout/Sidebar";
import { Footer } from "layout/Footer";
import { useBreakpoint } from "hooks/useBreakpoint";
import { GoBack } from "layout/GoBack";
import { cartGet } from "services/cartApi";
import { useCart } from "context/CartContext";
import { useUser } from "context/UserContext";
import { getCurrentUser } from "services/userApi";
import { getFavorite } from "services/favoriteApi";
import { ScrollToTop } from "components/ScrollToTop";

import styles from "./account.module.scss";

export interface IAccount {
  children: ReactNode;
  breadCrumbs?: TBreadCrumbsItem[];
}

export const AccountLayout: FC<IAccount> = ({ children, breadCrumbs }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { breakpoint, isDesktop } = useBreakpoint();
  const [shownMenu, setShownMenu] = useState(false);
  const { setCart, setCartLoading, setFavorite } = useCart();
  const { setUser, setAuthorized, setUserLoading } = useUser();

  useEffect(() => {
    const body = document.body;
    if (shownMenu === true) {
      body.classList.add("noScroll");
    } else {
      body.classList.remove("noScroll");
    }
  }, [shownMenu]);

  useEffect(() => {
    setCartLoading(true);
    cartGet()
      .then((res) => setCart(res.cart))
      .catch((e) => console.error(e))
      .finally(() => setCartLoading(false));

    if (!localStorage.getItem("clientAccessToken")) {
      setAuthorized(false);
      navigate({ pathname: "/login" });
      return;
    }

    setAuthorized(true);

    setUserLoading(true);
    getCurrentUser()
      .then(setUser)
      .catch((e) => console.error(e))
      .finally(() => setUserLoading(false));

    getFavorite()
      .then(setFavorite)
      .catch((e) => toast.error(e.response.data.message))
      .finally(() => {});

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Header setShownMenu={setShownMenu} shownMenu={shownMenu} />
      <Sidebar shownMenu={shownMenu} setShownMenu={setShownMenu} />
      <div className={classNames(styles.wrapper, styles[breakpoint])}>
        <main>{children}</main>
        <Footer />
        <ScrollToTop />
      </div>
    </div>
  );
};
