import { FC, forwardRef, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import InputMask, { Props as InputMaskProps, ReactInputMask } from "react-input-mask";
import moment, { Moment } from "moment";
import classNames from "classnames";
import { useBreakpoint } from "hooks/useBreakpoint";

import { Input } from "components/Input";
import { dateFormat, dateMonth } from "utils/format";
import { ucFirst } from "utils/ucFirst";
import { ReactComponent as ArrowIcon } from "assets/img/svg/arrow.svg";

import styles from "./dateRange.module.scss";

const PERIODS = [
  { id: "today", text: "Сегодня" },
  { id: "esterday", text: "Вчера" },
  { id: "week", text: "Неделя" },
  { id: "month", text: "Месяц" },
  { id: "quarter", text: "Квартал" },
];

interface IDataRange {
  setDtStart?: any;
  setDtEnd?: any;
  className?: string;
}

export const DateRange: FC<IDataRange> = ({
  setDtStart,
  setDtEnd,
  className }) => {
  const { breakpoint } = useBreakpoint();
  const [value, setValue] = useState("");
  const calendar = useRef<HTMLDivElement>(null);
  const [dateStart, setDateStart] = useState<Date | null>(null);
  const [dateEnd, setDateEnd] = useState<Date | null>(null);
  const [open, setOpen] = useState(false);
  const [mask, setMask] = useState("");
  const [period, setPeriod] = useState("");

  useEffect(() => {
    const handleClickOutside = ({ target }: MouseEvent): void => {
      if (
        !calendar?.current ||
        !(calendar?.current as Node).contains(target as Node)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  useEffect(() => {
    setDtStart(moment(dateStart).format("DD.MM.YYYY"));
    setDtEnd(moment(dateEnd).format("DD.MM.YYYY"));
  }, [dateStart, dateEnd]);

  const changeValue = (val: string) => {
    setValue(val);
    setPeriod("");
    if (val.length === 23) {
      const [start, end] = val.split(" - ");
      const startMoment = moment(start, "DD.MM.YYYY");
      const endMoment = moment(end, "DD.MM.YYYY");
      if (startMoment.isValid() && endMoment.isValid()) {
        setDateStart(startMoment.toDate());
        setDateEnd(endMoment.toDate());
      }
    }
  };

  const selectPeriod = (period: string) => {
    setPeriod(period);
    setMask("");

    switch (period) {
      case "today":
        setValue("Сегодня");
        setDateStart(new Date());
        setDateEnd(new Date());
        break;
      case "esterday":
        setValue("Вчера");
        setDateStart(moment().subtract(1, "days").toDate());
        setDateEnd(moment().subtract(1, "days").toDate());
        break;
      case "week":
        const start = moment().startOf("week").add(1, "days");
        setValue(`${start.format("DD.MM.YYYY")} - ${moment().format("DD.MM.YYYY")}`);
        setDateStart(start.toDate());
        setDateEnd(new Date());
        break;
      case "month":
        setValue(`${ucFirst(dateMonth(new Date()))}`);
        setDateStart(moment().startOf("month").toDate());
        setDateEnd(new Date());
        break;
      case "quarter":
        setValue(`${moment().quarter()} квартал ${moment().format("YYYY")} г`);
        setDateStart(moment().startOf("quarter").toDate());
        setDateEnd(new Date());
        break;
      default:
        break;
    }

    setOpen(false);
  };

  const getDateText = (date: Moment) => {
    if (date.isSame(moment().subtract(1, "days"), "day")) {
      return "Вчера";
    };
    if (date.isSame(moment(), "day")) {
      return "Сегодня";
    };
    return false;
  };

  const changePeriod = (dir: string) => {

    switch (period) {
      case "today":

        setDateStart(prev => {
          const date = dir === "left" ? moment(prev).subtract(1, "days") : moment(prev).add(1, "days");
          setValue(getDateText(date) || dateFormat(date.toDate()));
          return date.toDate();
        });
        setDateEnd(prev => {
          const date = dir === "left" ? moment(prev).subtract(1, "days") : moment(prev).add(1, "days");
          return date.toDate();
        });

        break;
      case "esterday":
        setDateStart(prev => {
          const date = dir === "left" ? moment(prev).subtract(1, "days") : moment(prev).add(1, "days");
          setValue(getDateText(date) || dateFormat(date.toDate()));
          return date.toDate();
        });
        setDateEnd(prev => {
          const date = dir === "left" ? moment(prev).subtract(1, "days") : moment(prev).add(1, "days");
          return date.toDate();
        });

        break;
      case "week":
        let dateForValue = moment();
        setDateStart(prev => {
          const date = dir === "left" ?
            moment(prev).subtract(1, "weeks").startOf("isoWeek") :
            moment(prev).add(1, "weeks").startOf("isoWeek");
          dateForValue = date;
          return date.toDate();
        });
        setDateEnd(prev => {
          const date = dir === "left" ?
            moment(prev).subtract(1, "weeks").endOf("isoWeek") :
            moment(prev).add(1, "weeks").endOf("isoWeek");
          setValue(`${dateFormat(dateForValue)} - ${dateFormat(date.toDate())}`);
          return date.toDate();
        });
        break;

      case "month":
        setDateStart(prev => {
          const date = dir === "left" ?
            moment(prev).subtract(1, "month").startOf("month") :
            moment(prev).add(1, "month").startOf("month");
          setValue(`${ucFirst(dateMonth(date.toDate()))}`);
          return date.toDate();
        });
        setDateEnd(prev => {
          const date = dir === "left" ?
            moment(prev).subtract(1, "month").endOf("month") :
            moment(prev).add(1, "month").endOf("month");
          return date.toDate();
        });
        break;
      case "quarter":
        setDateStart(prev => {
          const date = dir === "left" ?
            moment(prev).subtract(1, "quarter").startOf("quarter") :
            moment(prev).add(1, "quarter").startOf("quarter");
          setValue(`${moment(date).quarter()} квартал ${moment(date).format("YYYY")} г`);
          return date.toDate();
        });
        setDateEnd(prev => {
          const date = dir === "left" ?
            moment(prev).subtract(1, "quarter").endOf("quarter") :
            moment(prev).add(1, "quarter").endOf("quarter");
          return date.toDate();
        });
        break;
      default:
        break;
    }

  };

  return <div className={classNames(
    styles.dateRange,
    styles[breakpoint],
    className)
  }>
    <div ref={calendar}>
      <div className={styles.inputWrapper}>
        <div className={styles.prevButton} onClick={() => changePeriod("left")} ><ArrowIcon /></div>
        <div onClick={() => setOpen(true)} className={styles.input}>
          <Input
            id="dateStart"
            label="Период"
            onChange={e => changeValue(e.target.value)}
            onKeyDown={() => {
              setMask("99.99.9999 - 99.99.9999");
            }}
            value={value}
            mask={mask}
          />
        </div>
        <div className={styles.nextButton} onClick={() => changePeriod("right")} ><ArrowIcon /></div>
      </div>

      {open && <>
        <div className={styles.calendar}>
          <div className={styles.periods}>

            {PERIODS.map(val => <button
              key={val.id}
              type="button"
              className={classNames({ [styles.selected]: period === val.id })}
              onClick={() => selectPeriod(val.id)}>
              {val.text}
            </button>)}

          </div >
          <DatePicker
            selectsRange={true}
            startDate={dateStart || new Date()}
            endDate={dateEnd || undefined}
            dateFormat="dd.MM.yyyy"
            locale="ru"
            inline
            calendarClassName={styles.calendarBlock}
            onChange={(dates) => {
              const [start, end] = dates;
              setDateStart(start);
              setDateEnd(end);
              setValue(`${dateFormat(start)} - ${dateFormat(end)}`);
              if (start && end) {
                setOpen(false);
              }
              setPeriod("");
            }}
          />
        </div>
      </>
      }
    </div>
  </div >;
};